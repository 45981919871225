import { useRef } from "react";
import { ScrollArea } from "@/components/scroll-area";
import { ExtractReceiptType } from "@/types";
import {
  descriptionStyles,
  formatCurrency,
  getAccountNumber,
  Hooks,
  usePrint,
  useSendPDFtoMobile,
} from "@/utils";
import { formatDate } from "@/utils/date";
import { Button } from "@hyperlocal/vital2";
import { twMerge } from "tailwind-merge";
import { VerticalLogo } from "../vertical-logo";

export const PixReceiptV2 = (props: ExtractReceiptType<"PIX">) => {
  const { definitionTerm, definitionDetail, definitionContainer } =
    descriptionStyles();

  const {
    amount,
    description,
    destination,
    origin,
    transactionDate,
    type,
    externalId,
  } = props;

  const componentRef = useRef<HTMLDivElement>(null);

  const handlePrint = usePrint({
    content: () => componentRef.current,
  });

  const { isAndroid, isIos } = Hooks.useMobileDetect();

  const isMobileDevice = isAndroid() || isIos();

  const { generateAndSendPDF } = useSendPDFtoMobile();

  const generatePDF = async () => {
    generateAndSendPDF(componentRef.current);
  };

  const isCashIn = type === "CASH_IN";

  const { name: payerName, pix: payerData } = origin;

  const { name: beneficiaryName, pix: beneficiaryData } = destination;

  const account = getAccountNumber(
    isCashIn ? origin.pix.bankAccountNumber : destination.pix.bankAccountNumber,
  );

  return (
    <div className="flex h-[100svh] flex-col overflow-hidden pt-6">
      <h4 className="font-inter -mt-2 ml-4 pb-4 text-2xl font-bold text-neutral-darkest">
        Comprovante
      </h4>
      <div
        className="mb-6 flex w-full flex-col items-center overflow-hidden"
        ref={componentRef}
      >
        <ScrollArea className="flex h-full w-full flex-col px-6">
          <VerticalLogo />
          <div className="flex w-full flex-col items-center overflow-hidden">
            <h5 className="font-base text-sm/md font-bold text-neutral-darkest">
              Transferência Pix
            </h5>
            <h5 className="mb-6 font-base text-sm/md font-bold text-primary-main">
              {isCashIn ? "Recebido" : "Realizado"}
            </h5>
            <div className="flex h-full w-full flex-col overflow-hidden">
              {description && (
                <>
                  <hr className="h-[1px] w-full bg-neutral-light" />
                  <dl className="my-4 h-full w-full">
                    <dt className={definitionTerm()}>Mensagem</dt>
                    <dd
                      className={twMerge(definitionDetail(), "mt-1 text-start")}
                    >
                      {description}
                    </dd>
                  </dl>
                </>
              )}
              <hr className="h-[1px] w-full bg-neutral-light" />
              <dl className={definitionContainer()}>
                <dt className={definitionTerm()}>Data da transferência</dt>
                <dd className={twMerge(definitionDetail())}>
                  {formatDate(transactionDate)}
                </dd>
                <dt className={definitionTerm()}>Valor</dt>
                <dd className={twMerge(definitionDetail())}>
                  {formatCurrency(amount)}
                </dd>
              </dl>
              <hr className="h-[1px] w-full bg-neutral-light" />
              <dl className={definitionContainer()}>
                <dt className={definitionTerm()}>Favorecido</dt>
                <dd className={twMerge(definitionDetail())}>
                  {beneficiaryName}
                </dd>
                {!isCashIn && (
                  <>
                    <dt className={definitionTerm()}>Agência</dt>
                    <dd className={twMerge(definitionDetail())}>
                      {beneficiaryData.bankBranchNumber}
                    </dd>
                    <dt className={definitionTerm()}>Conta</dt>
                    <dd className={twMerge(definitionDetail())}>
                      {account.accountNumber}-{account.accountDigit}
                    </dd>
                    {beneficiaryData.key && (
                      <>
                        <dt className={definitionTerm()}>Chave Pix</dt>
                        <dd className={twMerge(definitionDetail())}>
                          {beneficiaryData.key}
                        </dd>
                      </>
                    )}
                  </>
                )}
              </dl>

              <hr className="h-[1px] w-full bg-neutral-light" />
              <dl className={definitionContainer()}>
                <dt className={definitionTerm()}>Pagador</dt>
                <dd className={twMerge(definitionDetail())}>{payerName}</dd>
                {isCashIn && (
                  <>
                    <dt className={definitionTerm()}>Agência</dt>
                    <dd className={twMerge(definitionDetail())}>
                      {payerData.bankBranchNumber}
                    </dd>
                    <dt className={definitionTerm()}>Conta</dt>
                    <dd className={twMerge(definitionDetail())}>
                      {account.accountNumber}-{account.accountDigit}
                    </dd>
                    {payerData.key && (
                      <>
                        <dt className={definitionTerm()}>Chave Pix</dt>
                        <dd className={twMerge(definitionDetail())}>
                          {payerData.key}
                        </dd>
                      </>
                    )}
                  </>
                )}
              </dl>

              <hr className="h-[1px] w-full bg-neutral-light" />
              <div className="col-span-2">
                <dl className="mt-4 w-full">
                  <dt className={definitionTerm()}>Id da Operação</dt>
                  <dd
                    className={twMerge(
                      definitionDetail(),
                      "break-all text-start",
                    )}
                  >
                    {externalId}
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </ScrollArea>
      </div>
      {isMobileDevice ? (
        <Button.Root className="m-6 mt-auto" onClick={generatePDF}>
          <Button.Slot name="ComputersShare" />
          Compartilhar comprovante
        </Button.Root>
      ) : (
        <Button.Root className="m-6 mt-auto" onClick={handlePrint}>
          <Button.Slot name="GeralPrint" />
          Imprimir comprovante
        </Button.Root>
      )}
    </div>
  );
};
