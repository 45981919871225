import {
  API,
  appVersionQueryFactory,
  formatCurrency,
  getReceiptRoutesData,
  getVertical,
  Hooks,
  JSpdf,
  Notifications,
  queries,
  RECEIPT_CDN,
  setHeader,
  useAccountStore,
  useSendNativeEvent,
  useSendPDFtoMobile,
  Vertical,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
} from "@hyperlocal/banking-utility";

const routes = getReceiptRoutesData();

export {
  API,
  appVersionQueryFactory,
  formatCurrency,
  getVertical,
  Hooks,
  JSpdf,
  Notifications,
  queries,
  RECEIPT_CDN,
  routes,
  setHeader,
  useAccountStore,
  useSendNativeEvent,
  useSendPDFtoMobile,
  Vertical,
};
